<template>
  <div id="unfunded_accounts">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.unfundedAccs') }}</h2>

          <p></p>
        </div>

        <div class="unfunded_content">
          <ul class="unfunded_tab">
            <li
              v-on:click="getNeverFundedAccounts"
              class="white_button li_button"
              v-bind:class="{ active: selectedCard == 'neverFunded' }"
              data-testid="neverFunded"
            >
              {{ $t('unfundedAcc.neverFundedAccs') }}
            </li>

            <li
              v-on:click="getZeroBalanceAccounts"
              class="white_button li_button"
              v-bind:class="{ active: selectedCard == 'zeroBalance' }"
              data-testid="zeroBalance"
            >
              {{ $t('unfundedAcc.prevFundedZero') }}
            </li>
          </ul>

          <ul class="table_content clearfix">
            <li v-bind:class="{ active: selectedCard == 'neverFunded' }">
              <div class="table_box">
                <el-table ref="traderTable" :data="displayData" style="width: 100%" :fit="true">
                  <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="100">
                    <template slot-scope="traderTableScope">
                      {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('common.keys.ACCNUM')" min-width="100">
                    <template slot-scope="scope">
                      <p v-for="(item, index) in scope.row.accounts" :key="index" class="scopeItem">{{ item }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="name" :label="$t('common.keys.NAME')"></el-table-column>

                  <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>

                  <el-table-column
                    prop="accountType"
                    :label="$t('common.keys.ACCTYPE')"
                    column-key="accountType"
                    min-width="100"
                  >
                    <template slot-scope="traderTableScope">
                      {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="platform"
                    :label="$t('common.keys.PLATFORM')"
                    column-key="platform"
                    min-width="100"
                  >
                    <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="baseCurrency"
                    :label="$t('common.keys.BASECURRENCY')"
                    min-width="100"
                  ></el-table-column>

                  <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" min-width="100">
                    <template slot-scope="traderTableScope">
                      {{ traderTableScope.row.accountBalance | currency('') }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <!-- 分页 -->

              <div class="page_box clearfix">
                <pagination v-bind:table-data="tableNeverFunded" v-bind:display-data.sync="displayData"></pagination>
              </div>
            </li>

            <li v-bind:class="{ active: selectedCard == 'zeroBalance' }">
              <div class="table_box">
                <el-table ref="traderTable" :data="displayData1" style="width: 100%">
                  <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="100">
                    <template slot-scope="traderTableScope">
                      {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('common.keys.ACCNUM')" min-width="100">
                    <template slot-scope="scope">
                      <p v-for="(item, index) in scope.row.accounts" :key="index" class="scopeItem">{{ item }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="name" :label="$t('common.keys.NAME')"></el-table-column>

                  <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>

                  <el-table-column
                    prop="accountType"
                    :label="$t('common.keys.ACCTYPE')"
                    column-key="accountType"
                    min-width="100"
                  >
                    <template slot-scope="traderTableScope">
                      {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="platform"
                    :label="$t('common.keys.PLATFORM')"
                    column-key="platform"
                    min-width="100"
                  >
                    <template slot-scope="traderTableScope">{{ 'MT' + traderTableScope.row.platform }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="baseCurrency"
                    :label="$t('common.keys.BASECURRENCY')"
                    min-width="100"
                  ></el-table-column>

                  <el-table-column prop="accountBalance" :label="$t('common.keys.BALANCE')" min-width="100">
                    <template slot-scope="traderTableScope">
                      {{ traderTableScope.row.accountBalance | currency('') }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="page_box clearfix">
                <pagination v-bind:table-data="tableZeroBalance" v-bind:display-data.sync="displayData1"></pagination>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { apiGetAccsByUrl } from '@/resource';
export default {
  components: { Pagination },

  data() {
    return {
      tableData: [],
      tableNeverFunded: [],
      tableZeroBalance: [],
      displayData: [],
      displayData1: [],
      pageNo: 1,
      userId: '',
      tableIndex: 0,
      selectedCard: 'neverFunded'
    };
  },
  methods: {
    getZeroBalanceAccounts() {
      this.selectedCard = 'zeroBalance';
      let url = 'zerobalance';
      if (this.tableZeroBalance.length === 0)
        this.getAccounts(this.userId, url).then(resp => {
          if (resp.data.code === 0 && resp.data.data.length >= 0) {
            this.tableZeroBalance = resp.data.data;
          } else if (resp.data.code === 305) {
            this.tableZeroBalance = [];
          }
        });
    },
    getNeverFundedAccounts() {
      this.selectedCard = 'neverFunded';
      let url = 'neverfunded';
      if (this.tableNeverFunded.length === 0)
        this.getAccounts(this.userId, url).then(resp => {
          if (resp.data.code === 0 && resp.data.data.length >= 0) {
            this.tableNeverFunded = resp.data.data;
          } else if (resp.data.code === 305) {
            this.tableNeverFunded = [];
          }
        });
    },
    getAccounts(userId, url) {
      let data = { userId: userId };
      return apiGetAccsByUrl(url, data);
    }
  },
  mounted() {
    this.userId = this.$store.state.common.CUID;
    this.getNeverFundedAccounts(this.userId);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/unfundedAccounts.scss';
</style>
